.App {
    /*text-align: center;*/
}

.App-logo {
    /*height: 40vmin;*/
    height: 75px;
    pointer-events: none;
}

.Client-logo {
    /*height: 40vmin;
    height: 150px;  filter: invert(1);
        pointer-events: none;
    filter: invert(1);
    filter: grayscale(1);
    filter: brightness(1);
    */
    width: 150px;
}

.Client-logo-invert {
    /*height: 40vmin;
    height: 150px;  filter: invert(1);
        pointer-events: none;
    filter: invert(1);
    filter: grayscale(1);
    filter: brightness(1);
    */
    width: 150px;
    filter: brightness(0) invert(1) grayscale(1);
}

.Client-logo-grey {
    /*height: 40vmin;
    height: 150px;  filter: invert(1);
        pointer-events: none;
    filter: invert(1);
    filter: grayscale(1);
    filter: brightness(1);
    */
    filter: invert(0) brightness(0);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-section {
    background-color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-section-half {
    background-color: #111;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-section-third {
    background-color: #111;
    min-height: 33vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-section-quarter {
    background-color: #ccc;
    min-height: 25vh;
    height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* CSS */

.button-21 {
    align-items: center;
    appearance: none;
    background-color: #e91e63;
    background-image: linear-gradient(1deg, #4F58FD, #e91e63 99%);
    background-size: calc(100% + 40px) calc(100% + 40px);
    border-radius: 100px;
    border-width: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-flex;
    font-family: CircularStd, sans-serif;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    line-height: 1.5;
    padding: 12px 40px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color .2s, background-position .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
}

.button-21:active,
.button-21:focus {
    outline: none;
}

.button-21:hover {
    background-position: -20px -20px;
}

.button-21:focus:not(:active) {
    box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 0.125em;
}