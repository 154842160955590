.App-logo2 {
    /*height: 40vmin;*/
    height: 50px;
    pointer-events: none;
}

.menu-container {
    /*
        
            position: absolute;
    top: 0;
    left: 0;
      flex: 1;
    
    position: absolute;
    */
    position: sticky;
    top: 0;
    left: 0;
    min-height: 60px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #111;
    color: #fff;
}

.menu-container-transparent {
    /*
        
            position: absolute;
    top: 0;
    left: 0;
      flex: 1;
   
    position: absolute;
     */
    position: sticky;
    top: 0;
    left: 0;
    min-height: 60px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #fff;
    margin-bottom: -80px;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color: #fff;
    text-decoration: underline;
}

a:active {
    color: #fff;
    text-decoration: underline;
}